import Link from 'next/link'
import React from "react"
import Image from 'next/image'

export default function Footer() {
    let currentYear = (new Date).getFullYear();
    return (
        <footer className="text-center text-lg-start">
            <div className="container p-4" style={{"paddingBottom":"0px !important"}}>
                <div className="row">
                    <div className="col-lg-3 col-md-12 mb-4 mb-md-0">
                        <Image className="navbar-brand" width="212" height="40" src="/images/header-logo.png"
                               alt={"IrsExtensions.Online logo"}/>                        
                    </div>
                    <div className="col-lg-9 col-md-12 mb-4 mb-md-0">
                        <ul className="navbar">
                            <li className="nav-item">
                                <Link href="/">
                                    <a className="nav-link">Home</a>
                                </Link>
                            </li>
                            <li className="desh">-</li>
                            <li className="nav-item">
                                <Link href="/login">
                                    <a className="nav-link">Customer Login</a>
                                </Link>
                            </li>
                            <li className="desh">-</li>
                            <li className="nav-item">
                                <Link href="/contact-us">
                                    <a className="nav-link">Contact Us</a>
                                </Link>
                            </li>
                            <li className="desh">-</li>
                            <li className="nav-item">
                                <Link href={"https://blog." + process.env.NEXT_PUBLIC_WEBSITE_HOST}>
                                    <a className="nav-link">Blog</a>
                                </Link>
                            </li>
                            <li className="desh">-</li>
                            <li className="nav-item">
                                <Link href="/faq">
                                    <a className="nav-link">FAQ</a>
                                </Link>
                            </li>
                            <li className="desh">-</li>
                            <li className="nav-item">
                                <Link href="/terms-of-use">
                                    <a className="nav-link">Terms of Use
                                    </a>
                                </Link>
                            </li>
                            <li className="desh">-</li>
                            <li className="nav-item">
                                <Link href="/privacy-policy">
                                    <a className="nav-link">Privacy Policy</a>
                                </Link>
                            </li>
                            <li className="desh">-</li>
                            <li className="nav-item">
                                <Link href="/refund-policy">
                                    <a className="nav-link">Refund policy</a>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-12 col-md-12 mb-4 mb-md-0">
                        <div className="ssl">
                            <Image src="/images/ssl1.png" width="64" height="64" alt="ssl protected"/>
                            <span>  </span>
                            <Image src="/images/ssl2b.png" width="64" height="64" alt="ssl protected 2"/>
                            <span>  </span>
                            <Image src="/images/ssl3.png" width="64" height="61" alt="ssl protected 3"/>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 mb-4 mb-md-0">
                    <div className="text-center p-3">
                        <strong>Important Disclaimers:</strong> {process.env.NEXT_PUBLIC_WEBSITE_HOST} is designated as an Authorized IRS e-file
                        Provider. An Authorized IRS e-file Provider (Provider) is a business or organization
                        authorized by the
                        IRS to participate in the IRS e-file program.
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 mb-4 mb-md-0">
                    <div className="text-center p-3">
                        © {currentYear}
                        &nbsp;<a href={"https://" + process.env.NEXT_PUBLIC_WEBSITE_HOST}>{process.env.NEXT_PUBLIC_WEBSITE_NAME}</a>&nbsp;
                        All right reserved
                    </div>
                </div>
            </div>            
        </footer>
    )
}
